import React, { useState } from 'react'
import Sidebar from '../../parts/Sidebar';
import DashboardFooter from '../../parts/DashboardFooter';
import { toast } from "react-toastify";
import Spinner from '../../parts/Spinner';
import { Helmet } from "react-helmet";
import AliceCredForm from '../../Forms/AliceCredForm';
import { useAliceCredentical, useChangeAliceCredentical, useValidateAliceCredentical } from '../../../hooks/aliceblue';
import moment from "moment";


const AliceblueCredential = (props) => {
	const [ error, setError ] = useState(null);
	const { aliceData, isLoading } = useAliceCredentical();
	const { handleChangeCredentical, changesIsLoading } = useChangeAliceCredentical();
	const { handleValidateCredentical, validateIsLoading } = useValidateAliceCredentical();

	const handleSubmit = async (values) => {
		const res = await handleChangeCredentical(values);
		if (res.status !== 201) {
			setError(res.detail)
			setError(res.api_key[0])
			toast.error('Saving failed.')
		} else {
			setError(null)
			toast.success('Saved changes.')
		}
	}

	const handleValidate = async () => {
		const res = await handleValidateCredentical();
		if (res.status !== 202) {
			setError(res.detail)
			toast.error('Credential is invalid.')
		} else {
			setError(null)
			toast.success('Credential is validated.')
		}
	}
	return (
		<>
			<Helmet>
				<title>Alice Credential Form - Javelin Traders</title>
			</Helmet>
			<div className=" page-body-wrapper-one">
				<div className='d-lg-block d-none'>
					<Sidebar user={props.user} />
				</div>

				<div className="main-panel">

					<div className="content-wrapper">
						<div className="row">
							<div className="col-md-6">
								<div className="card">
									<div className="card-body">
										<h3 className="fw-light mb-4">Configure Alice Blue Account</h3>
										{aliceData && !isLoading ?
											<AliceCredForm
												aliceData={aliceData}
												changesIsLoading={changesIsLoading}
												handleSubmit={handleSubmit}
												handleValidate={handleValidate}
												validateIsLoading={validateIsLoading}
											/>
											:
											<h5 className="fw-light text-center">Loading... <Spinner /></h5>
										}
										{error && <div className="d-flex justify-content-center mt-2">
											<span className='alert-danger alert rounded p-2'>
												{error}
											</span>
										</div>}
									</div>
								</div>
							</div>
							<div className="col-md-6">
								<div className="card">
									<div className="card-body">
										{aliceData && !isLoading ?
											<>
												<label className="fw-light">User ID</label>
												<p>{aliceData.api_user_id}</p>
												<label className="fw-light">API Key</label>
												<p>{aliceData.api_key}</p>
												<label className="fw-light">Created At</label>
												<p>{moment(aliceData.created_at).format('DD-MM-YYYY HH:mm:ss')} </p>
												{aliceData.is_validated ?
													<>
														<label className="fw-light">Validated At</label>
														<p>{moment(aliceData.updated_at).format('DD-MM-YYYY HH:mm:ss')}</p>
														{
															validateIsLoading ?
																<button className="btn signbtn" disabled>
																	<span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>  Validating...
																</button>
																:
																<button className="btn btn-success" onClick={handleValidate}> Re-validate Credential</button>
														}
													</>
													:
													<p className="d-flex justify-content-center mt-3">
														<span className='alert-danger alert rounded p-2'>
															API Key is not validated! Please validate your API key.
														</span>
													</p>
												}
											</>
											:
											<h5 className="fw-light text-center">Loading... <Spinner /></h5>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
					<DashboardFooter />
				</div>

			</div>
		</>
	)
}

export default AliceblueCredential