import React from 'react';
import './Loading.css'; // Assuming you have a CSS file for custom styles

export default function Loading() {
    return (
        <div className="container d-flex justify-content-center align-items-center" style={{ height: '70vh' }}>
            <div>
                <center>
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </center>
                <div className="display-6 text-center mt-3">Please wait...</div>
                {/* <div className='line-bar mx-auto' ></div> */}
            </div>
        </div>
    );
}