import { Formik, Form } from 'formik';
import * as Yup from 'yup'
import { InputField } from '../Common/Fields';

export default function AliceCredForm({ aliceData, changesIsLoading, handleSubmit, handleValidate, validateIsLoading }) {
    const validate = Yup.object({
        api_user_id: Yup.string().required('Alice Blue User ID is required.'),
        api_key: Yup.string().required('Alice Blue API Key is required.').min(10, 'Must be at least 10 characters.')
    })
    const { api_user_id, api_key, is_validated } = aliceData;
    return (
        <Formik
            initialValues={{
                api_user_id: api_user_id,
                api_key: api_key,
            }}
            validationSchema={validate}
            onSubmit={(values, { resetForm }) => handleSubmit(values, resetForm)}
        >
            <Form>
                <InputField label='User ID' type='text' name='api_user_id' placeholder='User ID' />
                <InputField label='API Key' type='text' name='api_key' placeholder='API Key' />

                <div className='d-flex justify-content-center'>
                    {changesIsLoading ?
                        <button className="btn signbtn mx-2" disabled>
                            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>  Saving...
                        </button>
                        :
                        <button className="btn btn-primary mx-2"> Save Credential</button>
                    }
                    {api_user_id && !is_validated &&
                        (
                            validateIsLoading ?
                                <button className="btn signbtn" disabled>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>  Validating...
                                </button>
                                :
                                <button className="btn btn-success" onClick={handleValidate}> Validate Credentials</button>
                        )
                    }
                </div>
            </Form>
        </Formik>
    )
}
