/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import AxiosCall from '../../AxiosCall';
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import LoginImg from './login-one.png'
import { Helmet } from "react-helmet";

import './Login.scss'
import LoginForm from '../../Forms/LoginForm';
import toast from 'react-hot-toast';
import axios from 'axios';

const LoginPage = (props) => {
    const [loading, setLoading] = useState(false)
    const handleSubmit = async (values, { resetForm }) => {
        const { username, password } = values;
        setLoading(true)
        var toastId = toast.loading('Processing...');

        const formData = new FormData()
        formData.append('username', username)
        formData.append('password', password)

        await axios.post(props.user.url + '/api/login/', formData, {
            'Content-Type': 'multipart/form-data',
        }).then(resp => {
            if (resp?.data?.success) {
                localStorage.setItem('user_data', JSON.stringify(resp?.data?.data))
                toast.success(resp?.data?.detail || 'Validation successful.', { id: toastId });
                window.location.href = ('/account/trade/start')
            }
            else {
                toast.error(resp?.data?.detail || 'Invalid Username & password.', { id: toastId });
            }
            setLoading(false)
        })
            .catch(err => {
                if (err.response) {
                    toast.error(err?.response?.data?.detail, { id: toastId });
                } else {
                    toast.error('Something wents wrong ..', { id: toastId });
                }
                
                setLoading(false)
            })

    }

    function show_pass(id) {
        let elm = document.getElementById(id)
        if (elm.type === 'password') {
            elm.type = 'text'
        }
        else {
            elm.type = 'password'
        }

    }

    useEffect(() => {
        let nav_btn = document.getElementById("nav_btn")
        let nav_btn_mb = document.getElementById('nav_btn_mb')
        if (nav_btn_mb)
            nav_btn.classList.add('notshow')
        if (nav_btn_mb)
            nav_btn_mb.classList.add('notshow')

    }, [])

    return (
        <>
            <Helmet>
                <title>Login - Javelin Traders</title>
            </Helmet>
            <div className='login-page' >

                <div className="container">
                    <div className='row align-items-center'    >
                        <div className='col-lg-6' >
                            <img className='w-100' alt='' src={LoginImg} />
                        </div>
                        <div className='col-lg-6'>
                            <div className='login-text'>
                                <h4>
                                    Hello <br />
                                    Welcome back
                                </h4>


                                <div>
                                    <LoginForm handleSubmit={handleSubmit} isLoading={loading} />
                                </div>
                                <div className="text-center mt-4 font-weight-light">
                                    Don't have an account? <Link to="/signin" className="text-primary">Create Account</Link>
                                </div>
                                <div className='mt-2 text-center'>
                                    <a style={{ color: "red" }} href='https://docs.google.com/document/d/123QoplLIpoRta_HEn-HCGRrR7DtCpSxHg1j7uzIUm2Y/edit' target="_blank" >Click Here For Help</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default LoginPage