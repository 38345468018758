import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom';
import './Login.scss'
import Logintwo from './login-two.png'
import { useRegistration } from '../../../hooks/user';
import { Helmet } from "react-helmet";
import RegisterForm from '../../Forms/RegisterForm';
import toast from 'react-hot-toast';

const SigninPage = (props) => {
    const history = useHistory()
    const { isLoading, handleRegistration } = useRegistration()

    const handleSubmit = async (values, { resetForm }) => {
        const { first_name, last_name, email, phone, password, profession, city, state } = values;
        const toastId = toast.loading('Processing...');
        const data = await handleRegistration(first_name, last_name, email, phone, profession, city, state, password);
        if (data.status === 201) {
            toast.success('Registration successful.', { id: toastId });
            history.push('/login')
        } else {
            toast.error(data?.detail || 'Something wents wrong...', { id: toastId });
        }
    }
    
    useEffect(() => {
        let nav_btn = document.getElementById("nav_btn")
        let nav_btn_mb = document.getElementById('nav_btn_mb')
        if (nav_btn)
            nav_btn.classList.add('notshow')
        if (nav_btn_mb)
            nav_btn_mb.classList.add('notshow')
    }, [])

    return (
        <>
            <Helmet>
                <title>Sign Up - Javelin Traders</title>
            </Helmet>
            <div className='login-page'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <img className='w-100' src={Logintwo} alt='' />
                        </div>
                        <div className='col-lg-6'>
                            <div className='login-text'>
                                <h4>
                                    Create <br />
                                    Your Account
                                </h4>
                                <div className="pt-3">
                                    <RegisterForm handleSubmit={handleSubmit} isLoading={isLoading} />                                  

                                    <div className="text-center mt-4 font-weight-light">
                                        I have an account? <Link to="/login" className="text-primary">Login Account</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {loading ?
                        <div>
                            <div className="spinner-border mt-3" role="status">
                                <span className="sr-only">Loading...</span>

                            </div>
                            <span className="ml-3 text-danger">Checking Your Credential...</span>
                        </div>
                        :
                        ''
                    } */}
                </div>
            </div>
        </>
    )
}

export default SigninPage