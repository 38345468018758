import { useMutation, useQuery, useQueryClient } from 'react-query';
import { API_HOST_URL } from '../config';
import { endpoints } from '../config/endpoints';
import { fetchJson } from '../lib/api';
import { getTokens } from '../utils/shortcuts';

const ALICE_QUERY_KEY = 'alice';


export function useChangeAliceCredentical() {
    const { accessToken } = getTokens();
    const queryClient = useQueryClient();
	const mutation = useMutation(({ api_user_id, api_key }) => fetchJson(`${API_HOST_URL}/${endpoints.alice.credential}`, {
		method: 'POST',
		headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
		body: JSON.stringify({ api_user_id, api_key })
	}, true))
    return { 
        handleChangeCredentical: async ({ api_user_id, api_key }) => {
            try {
                const res = await mutation.mutateAsync({ api_user_id, api_key });
                const data = await res.json()
                if (res.status === 201) {
                    queryClient.setQueryData([ALICE_QUERY_KEY], data);
                }
                return {
                    status: res.status,
                    ...data
                }
            } catch (err) {
                return false
            }            
        },
        changesIsLoading: mutation.isLoading,
    }
}

export function useValidateAliceCredentical() {
    const { accessToken } = getTokens();
    const queryClient = useQueryClient();
	const mutation = useMutation(() => fetchJson(`${API_HOST_URL}/${endpoints.alice.validate}`, {
		method: 'GET',
		headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
	}, true))
    return { 
        handleValidateCredentical: async () => {
            try {
                const res = await mutation.mutateAsync();
                const data = await res.json()
                if (res.status === 202) {
                    queryClient.setQueryData([ALICE_QUERY_KEY], data);
                }
                return {
                    status: res.status,
                    ...data
                }
            } catch (err) {
                return false
            }            
        },
        validateIsLoading: mutation.isLoading,
    }
}


export function useAliceCredentical() {
    const { isLoading, error, data, status } = useQuery([ALICE_QUERY_KEY], async () => {
        try {
            const { accessToken } = getTokens();
            return await fetchJson(`${API_HOST_URL}/${endpoints.alice.credential}`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            })
        } catch (err) {
            return undefined;
        }
    }, {
        // cacheTime: Infinity,
        // staleTime: 30_000, // ms
    })
    return { aliceData: data, isLoading, error, status }
}