import React from 'react'
import { Button, Modal } from 'react-bootstrap';

export default function WelcomeModal({modal, setModal}) {
    return (
        <Modal show={modal} onHide={setModal}>
            <Modal.Header closeButton>
                <Modal.Title>Welcome to Javelin Algo!</Modal.Title>
            </Modal.Header>
            <Modal.Body>An algorithm or strategy is based on logic, 
                and no organization backs one's strategies, 
                so algo products aren't certified by any body or entity. 
                Their effectiveness is solely dependent on past reports. 
                We are merely a software service provider, 
                reliant on the broker for order placement, 
                and we are not legally obligated to guarantee results. 
                No other provider can guarantee results; this is determined by backtesting 
                and the logic used, so participating in this process is at your own risk. 
                Algo trading helps to reduce risk and enables trading in a disciplined manner. 
                Thank you."</Modal.Body>
            {/* <Modal.Footer>
                <Button variant="secondary" onClick={setModal}>
                    Close
                </Button>
                <Button variant="primary" onClick={setModal}>
                    Save Changes
                </Button>
            </Modal.Footer> */}
        </Modal>
    )
}
