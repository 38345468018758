import { Formik, Form } from 'formik';
import * as Yup from 'yup'
import { InputField } from '../Common/Fields';
import { Link } from 'react-router-dom';


export default function LoginForm({ handleSubmit, isLoading }) {
    const validate = Yup.object({

        username: Yup.string().required('Username is required.'),
        password: Yup.string()
            .min(6, 'Password must be at least 6 characters.')
            .required('Password is required.'),

    })
    return (
        <Formik
            initialValues={{
                username: '',
                password: '',
            }}
            validationSchema={validate}
            onSubmit={handleSubmit}
        >
            {formik => <Form>
                <div className='row'>

                    <div className='col-md-12'>
                        <InputField label='Username: ' autoComplete='on' type='text' name='username' placeholder='Your Email / Username' />
                    </div>

                    <div className='col-md-12'>
                        <InputField label='Password: ' type='password' name='password' placeholder='Password' />                        
                    </div>

                </div>
                <div className="mt-4 d-flex justify-content-end align-items-center">
                    <Link to="/account/forgot-password" className="auth-link text-black"><a>Forgot password?</a></Link>

                </div>

                <div className='d-flex justify-content-center'>
                    {isLoading ?
                        <button className="btn signbtn" disabled>
                            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>  Please wait...
                        </button>
                        :
                        <button className="btn signbtn" type="submit"> SIGN IN</button>
                    }
                </div>

            </Form>}
        </Formik>
    )
}
