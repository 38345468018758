export const getTokens = () => {
    const tokens = localStorage.getItem('user_data');
    if (tokens) {
        const { access_token, refresh_token } = JSON.parse(tokens)
        return {
            accessToken: access_token,
            refreshToken: refresh_token
        }
    } else {
        return {
            accessToken: '',
            refreshToken: ''
        }
    }
}