export const TRADING_STRATEGIES = [
    {
        id: 1,
        title: 'Delta',
        image: 'img1.jpg',
        slug: '/trading/1',
        total_subscriptions: '10',
        roi: '100%',
    },
    {
        id: 2,
        title: 'Theta',
        image: 'img2.jpg',
        slug: '/trading/2',
        total_subscriptions: '30',
        roi: '-',
    },
    {
        id: 2,
        title: 'Beta',
        image: 'img3.jpg',
        slug: null,
        total_subscriptions: 'N/A',
        roi: '-',
    },
]