import { Formik, Form } from 'formik';
import * as Yup from 'yup'
import { InputField } from '../Common/Fields';

export default function RegisterForm({ handleSubmit, isLoading }) {
    const validate = Yup.object({
        first_name: Yup.string().required('First Name is required.'),
        last_name: Yup.string().required('Last Name is required.'),
        email: Yup.string().email().required('Email is required.'),
        password: Yup.string()
            .min(6, 'Password must be at least 6 characters.')
            .required('Password is required.'),
        password2: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Password must match.')
            .required('Confirm password is required.'),
        profession: Yup.string().label('Profession'),
        city: Yup.string().label('City'),
        state: Yup.string().label('State'),
        phone: Yup.string().matches(/^[6-9]\d{9}$/, 'Invalid mobile number').required('Mobile number is required'),
    })
    return (
        <Formik
            initialValues={{
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                password: '',
                password2: '',
                profession: '',
                city: '',
                state: '',
            }}
            validationSchema={validate}
            onSubmit={handleSubmit}
        >
            <Form>
                <div className='row'>
                    <div className='col-md-6'>
                        <InputField label='First Name *' type='text' name='first_name' placeholder='First Name' />
                    </div>
                    <div className='col-md-6'>
                        <InputField label='Last Name *' type='text' name='last_name' placeholder='Last Name' />
                    </div>
                    <div className='col-md-6'>
                        <InputField label='Email *' type='email' name='email' placeholder='Your Email' />
                    </div>
                    <div className='col-md-6'>
                        <InputField label='Mobile *' type='text' name='phone' placeholder='Mobile number' />
                    </div>
                    <div className='col-md-6'>
                        <InputField label='Password *' type='password' name='password' placeholder='Password' />
                    </div>
                    <div className='col-md-6'>
                        <InputField label='Confirm Password *' type='password' name='password2' placeholder='Confirm Password' />
                    </div>
                    <div className='col-md-4'>
                        <InputField label='Profession' type='text' name='profession' placeholder='Profession' />
                    </div>
                    <div className='col-md-4'>
                        <InputField label='City' type='text' name='city' placeholder='City' />
                    </div>
                    <div className='col-md-4'>
                        <InputField label='State' type='text' name='state' placeholder='State' />
                    </div>
                </div>

                <div className='d-flex justify-content-center'>
                    {isLoading ?
                        <button className="btn signbtn" disabled>
                            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>  Please wait...
                        </button>
                        :
                        <button className="btn signbtn" type="submit"> SIGN UP</button>
                    }
                </div>
                {/* {response && response.status && 
					<p className="d-flex justify-content-center mt-3">
						<span className={`${response.status !== 200 ? 'alert-danger': 'alert-success'} alert rounded p-2`}>
							{response.detail}
						</span>
					</p>
                }  */}
            </Form>
        </Formik>
    )
}
