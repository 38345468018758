import React, { useEffect, useState } from 'react';
import Contact from '../../parts/Contact';
import Footer from '../../parts/Footer';
import AlgoInner from './parts/AlgoInner';
import BannerImage from './Banner-img.png'
import IconOne from './stock.png'
import AboutImg from './about-img.png'
import Training from '../../parts/Trainings';
import Aboutinner from './parts/Aboutinner';
// import Tone from './t1.png'
// import Ttwo from './t2.png'
// import Tthree from './t3.png'
import '../../../scss/Home.scss'
import Slider from "react-slick";
import { Helmet } from "react-helmet";
import mainone from "./man-1.jpg"
import maintwo from "./man-2.jpg"
import mainthree from "./man-3.jpg"
import WelcomeModal from '../../Home/WelcomeModal';


const Home = (props) => {
    const [welcomeModal, setWelcomeModal] = useState(false)

    useEffect(() => {
        let nav_btn = document.getElementById("nav_btn")
        let nav_btn_mb = document.getElementById('nav_btn_mb')
        // nav_btn.classList.add('notshow')
        // nav_btn_mb.classList.add('notshow')
        setWelcomeModal(true)
    }, [])


    const settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 500,
        // slidesToShow: 4,
        // slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 2500,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            // {
            //     breakpoint: 1199,
            //     settings: {
            //         slidesToShow: 1,
            //         slidesToScroll: 1,
            //         infinite: true,
            //         dots: true
            //     }
            // },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    const settingsone = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 500,

        // slidesToShow: 4,
        // slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 2500,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            // {
            //     breakpoint: 1199,
            //     settings: {
            //         slidesToShow: 1,
            //         slidesToScroll: 1,
            //         infinite: true,
            //         dots: true
            //     }
            // },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <>
            <Helmet>
                <title>Home - Javelin Traders</title>
            </Helmet>
            <WelcomeModal modal={welcomeModal} setModal={setWelcomeModal} />
            <div className='banner-design'>
                <div className='banner-inner'>
                    <div className='container'>
                        <div className='row align-items-center'>
                            <div className='col-lg-6 col-md-12'>
                                <img className='w-100 banner-img' src={BannerImage} />
                            </div>
                            <div className='col-lg-5 col-md-12 mt-lg-0 mt-5 offset-lg-1'>
                                <div className='zoombox-design mx-auto'>
                                    <div className='zoominnerbox    '>
                                        <img src={IconOne} />
                                        <h4>Leading Bot on Banknifty Options</h4>
                                    </div>
                                    <div className='zoominnerbox    '>
                                        <img src={IconOne} />
                                        <h4>Regularly trading Index Options</h4>
                                    </div>
                                    <div className='zoominnerbox    '>
                                        <img src={IconOne} />
                                        <h4>Flexible to Sideways and Trending markets</h4>
                                    </div>
                                    <div className='zoominnerbox    '>
                                        <img src={IconOne} />
                                        <h4>Brave and Intuitive algo setup</h4>
                                    </div>
                                    <div className='zoominnerbox    '>
                                        <img src={IconOne} />
                                        <h4>Believed by users and always motivating results</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Aboutinner />

            <div className='slider-javlin'>
                <div className='container' >
                    <AlgoInner user={props.user} />
                </div>
            </div>

            <Training />    

            <div className='accomation-slider'>
                <div className='container'>
                    <div className='heading'>
                        <h2 className="about-heading text-center ">Testimonials</h2>
                        <div className='line-bar mx-auto' ></div>
                    </div>
                    <Slider {...settingsone}>
                        <div className='ax-sliderbox'>
                            <div className='boxset'>
                                <div class="teseti-back">
                                    <div class="testimonials-photo">
                                        <img src={mainone} />
                                    </div>
                                    <div class="testimonials-name d-flex align-items-center mb-2">
                                        <h4>Sandeep</h4>
                                        - Full Time Traders
                                    </div>
                                    <div class="testimonials-desc">
                                        <p className='mb-0'><i class="fa fa-quote-left"></i>&nbsp;&nbsp;Javelin Traders is very much focused on giving 100% benefit-making strategies. I have been trading on their bot since the product was in beta mode. One of the best and most excellent platforms for trading the best strategies and complete guidance. &nbsp;&nbsp;<i class=" fa fa-quote-right"></i></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='ax-sliderbox'>
                            <div className='boxset'>
                                <div class="teseti-back">
                                    <div class="testimonials-photo">
                                        <img src={maintwo} />
                                    </div>
                                    <div class="testimonials-name d-flex align-items-center mb-2">
                                        <h4>Sanjay</h4>
                                        - Full Time Traders
                                    </div>
                                    <div class="testimonials-desc">
                                        <p className='mb-0'><i class="fa fa-quote-left"></i>&nbsp;&nbsp;I learned about the Derivatives and Options markets while trading with Javelin Traders. My experience with the company is memorable. The team is quite friendly. The research done by Javelin Traders is appreciable. - Thank You for your effort.&nbsp;&nbsp;<i class=" fa fa-quote-right"></i></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='ax-sliderbox'>
                            <div className='boxset'>
                                <div class="teseti-back">
                                    <div class="testimonials-photo">
                                        <img src={mainthree} alt="Riyaz Hadiyavala" />
                                    </div>
                                    <div class="testimonials-name d-flex align-items-center mb-2">
                                        <h4>Naresh</h4>
                                        - Full Time Traders
                                    </div>
                                    <div class="testimonials-desc">
                                        <p className='mb-0'><i class="fa fa-quote-left"></i>&nbsp;&nbsp;I am a working professional and now I need not worry about my trading I can handle my portfolio perfectly. - Best of luck to Javelin Traders waiting for more strategies and integrations. &nbsp;&nbsp;<i class=" fa fa-quote-right"></i></p>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </Slider>
                </div>
            </div>

            <Footer />


            <script type="text/javascript" src="/static/js/vanilla-tilt.min.js"></script>

        </>
    )
}

export default Home