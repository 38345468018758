import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import AxiosCall from '../AxiosCall';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';

export default function SubscriptionModal({ data, user, modal, setModal }) {
    const history = useHistory()
    const [isLoading, setLoading] = useState(false)
    async function submit_subscription(id) {
        const formData = new FormData()
        formData.append("id", id)


        try {
            setLoading(true)
            var toastId = toast.loading('Processing...');
            const resp = await AxiosCall({ method: 'post', url: `${user.url}/api/subscription/`, is_auth: true, post_data: formData });
            console.log(resp)
            if (resp.response === true) {
                // Since both conditions do the same thing, no need for an if/else here.
                const message =
                    toast.success(resp.bknd_data.message, { id: toastId });

                if (resp.bknd_data.status == 302) {
                    toast.error(`You have already subscribed ${resp.bknd_data?.algo?.name.toLowerCase()} package.`, { id: toastId })
                    history.push('/account/trade/start')
                }
                else {
                    toast.success(resp.bknd_data.message, { id: toastId })
                    history.push('/account/trade/start')
                }
                setModal(false);
            } else {
                toast.error("Subscription Failed.", { id: toastId });
            }
        } catch (error) {
            // Handle any errors that occur during the Axios call
            toast.error("An error occurred. Please try again.", { id: toastId });
        } finally {
            // Ensure setLoading(false) is called whether the request succeeds or fails
            setLoading(false);
        }

        // await AxiosCall({ method: 'post', url: `${user.url}/api/subscription/`, is_auth: true, post_data: formData }).then(resp => {

        //     if (resp.response == true) {
        //         if (resp.bknd_data.status == 302) {
        //             toast.success(resp.bknd_data.message, { toastId: toastId })
        //         }
        //         else {
        //             toast.success(resp.bknd_data.message, { toastId: toastId })
        //         }
        //     }
        //     else {
        //         toast.error("Subscription Failed.", { toastId: toastId })
        //     }
        //     setLoading(false)
        // })
    }
    return (
        <Modal show={modal} onHide={setModal}>
            <Modal.Header closeButton>
                <Modal.Title>Subscription Overview</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className='display-6' style={{ fontSize: 22 }}>
                    You are subscribing to <b className='text-primary'>{data.name}</b> plan
                </p>
                <p className='text-center fw-bold display-6 text-warning' style={{ fontSize: 18 }}> ₹ {data.amount} for {data.time_period} {data.time_period <= 1 ? data.time_type : `${data.time_type}s`}</p>
                <hr />
                <small><p className='text-danger'>After making the payment, please send the details to info@javelintraders.in. Your subscription will be activated within 8 hours.</p></small>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setModal(false)}>
                    Close
                </Button>
                <Button variant="primary" onClick={() => submit_subscription(data.id)} disabled={isLoading}>
                    {isLoading ? 'Processing...' : 'Confirm'}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
