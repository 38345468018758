import React, { useEffect, useState } from 'react'
import AxiosCall from '../../../AxiosCall'
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Spinner from '../../../parts/Spinner';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import imgone from "./img1.jpg"
import imgtwo from "./img2.jpg"
import imgthree from "./img3.jpg"
import usericon from "./profile.png"
import { TRADING_STRATEGIES } from '../../../../config/constants';


const AlgoInner = ({ user }) => {
    const [state, setState] = useState({
        data: {}
    })

    const [query, setQuery] = useState('0')

    const [loading, setLoading] = useState(false)


    async function load_data(val = query) {
        setLoading(true)

        await AxiosCall({ method: 'get', url: `${user.url}/api/strategie/?search=${val}` }).then(resp => {
            if (resp.response == true) {
                setState({ data: resp.bknd_data })
            }
            setLoading(false)
        })

    }

    function search_algo(e) {
        load_data(e.target.value)
        setQuery(e.target.value)

    }



    useEffect(() => {
        let mounted = true;

        if (mounted) {
            load_data()

        }
        return () => (mounted = false);
    }, []);
    
    return (
        <>
            {/* <ToastContainer /> */}

            <div className='container'>


                <div className="slider-javlin box-j-sliderBox">
                    <div className='heading one' >
                        <h2 className="about-heading text-center ">Select a Strategy to Trade</h2>
                        <div className='line-bar mx-auto' ></div>
                    </div>
                    <div className='dropbox-design mb-4 px-4'>
                        {/* <div className="broker-section d-flex align-items-center">
                            <p className=' algo-list'>Select Broker</p>
                            <select className='form-control form-size'
                                onChange={(e) => search_algo(e)}
                            >
                                {query == '0' ?
                                    <>
                                        <option value="0" selected >Alice Blue</option>
                                        <option value="1">Zerodha</option>
                                    </>
                                    :
                                    <>
                                        <option value="0" >Alice Blue</option>
                                        <option selected value="1">Zerodha</option>
                                    </>
                                }
                            </select>
                        </div> */}
                    </div>
                    <div>
                        <div className='row' >
                            {TRADING_STRATEGIES.map((val, i) => <div className=' col-lg-4 col-md-6' key={i}>
                                <div className="j-sliderBox ">
                                    <div className="card algo-card rounded shadow-sm border">
                                        <div className='card-img'>
                                            <img className='w-100' src={require(`./${val.image}`)} />
                                        </div>
                                        <div className="card-body my-card text-center "   >
                                            <h3 className="card-title heading mb-3 ">
                                                {val.title}
                                            </h3>
                                            <div className="d-flex text-icon flex-row justify-content-center align-items-center my-3">
                                                <img className='img-icon' src={usericon} />
                                                <span className="text-capitalize mx-3 test-text"><b>
                                                    {val.total_subscriptions}
                                                </b></span>
                                            </div>
                                            <h5 className='test-text'> ROI <span className='text-success'>{val.roi}</span></h5>
                                            {val.slug ? <Link to={val.slug} className="btn button mt-2">Continue with {val.title}</Link> : <button disabled className="btn button mt-2"> Coming Soon</button>}

                                        </div>
                                    </div>
                                </div>
                            </div>)}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AlgoInner